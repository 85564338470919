import Link from "next/link";
import { Box, Grid, Typography } from "@mui/material";

import Image from "next/image";
import { getImage } from "../utils/strapi";

const getUrl = (product) => {
  if (product.type == "hanger") {
    return {
      pathname: "/hangers/[productId]",
      query: { productId: product.slug },
    };
  } else {
    return "product/".concat(
      [product.slug, product.theme, product.size].join("-")
    );
  }
};

const ProductRow = ({ collection }) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <Grid container>
        <Grid container direction="column" marginBottom={4}>
          <Typography variant="h4">
            {collection?.title ?? collection?.Title}
          </Typography>
          <Typography variant="subtitle1">
            {collection?.description ?? collection?.Description}
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          {collection.items.map((product) => {
            const url = getUrl(product);
            const image = getImage(product);

            return (
              <Grid item key={product.slug} sx={{ cursor: "pointer" }}>
                <Link href={url}>
                  <Box sx={{ height: 400, width: 300 }}>
                    <Image
                      height="300px"
                      width="300px"
                      src={image.url}
                      alt={product.description}
                    />
                    <Box mt={2}>
                      <Typography variant="h5">{product.title}</Typography>
                      <Typography variant="subtitle2">
                        {product.description}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductRow;
