const SproutIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 40H34"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 40C31 35 21.6 27.2 26 20"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 18.7999C21.2 20.3999 22.6 23.1999 23.6 26.1999C19.6 26.9999 16.6 26.9999 14 25.5999C11.6 24.3999 9.4 21.7999 8 17.1999C13.6 16.1999 16.8 17.1999 19 18.7999V18.7999Z"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2001 12C26.6752 14.3831 25.9082 17.1723 26.0001 20C29.8001 19.8 32.6001 18.8 34.6001 17.2C36.6001 15.2 37.8001 12.6 38.0001 8C32.6001 8.2 30.0001 10 28.2001 12Z"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SproutIcon;
