import qs from "qs";
import { StrapiResponse } from "../types";

const strapiUrl = process.env.NEXT_PUBLIC_STRAPI_API_URL;
const token = process.env.NEXT_PUBLIC_STRAPI_TOKEN;

const fetchContent = async <T>(
  path: string,
  params: {} = { populate: "*" },
  options: {} = {}
): Promise<StrapiResponse<T>> => {
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    ...options,
  };

  const queryString = qs.stringify(params);
  console.log(queryString)
  const requestUrl = `${strapiUrl}${path}${
    queryString ? `?${queryString}` : ""
  }`;

  const response = await fetch(requestUrl, mergedOptions);
  if (!response.ok) {
    console.error(response.statusText);
    // throw new Error(`An error occured please try again`);
  }
  const data = (await response.json()) as StrapiResponse<T>;
  return data;
};

type imageSize = "small" | "medium" | "large"

const getImage = (product, size: imageSize = "medium") => {
  if (product.image?.data) {
    return {
      url: product.image.data.attributes.formats[size].url,
      height: product.image.data.attributes.formats[size].height,
      width: product.image.data.attributes.formats[size].width,
    };
  } else {
    return {
      url: product.image,
      height: "300px",
      width: "300px",
    };
  }
};

export { fetchContent, getImage };
