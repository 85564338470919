import { Grid, Typography } from "@mui/material";

const PerksItem = ({ Image, title, subtitle }) => {
  return (
    <Grid
      item
      width={"250px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Image />
      <Typography align={"center"} variant="h5">
        {title}
      </Typography>
      <Typography align={"center"} variant="subtitle2">
        {subtitle}
      </Typography>
    </Grid>
  );
};

export default PerksItem;
