import { Grid } from "@mui/material";
import CardIcon from "./icons/cardicon";
import FreightIcon from "./icons/freighticon";
import SproutIcon from "./icons/sprouticon";
import PerksItem from "./perksitem";

const PerksRow = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      spacing={4}
      sx={{
        width: "100%",
      }}
    >
      <PerksItem
        Image={FreightIcon}
        title={"Gratis frakt"}
        subtitle={"Vi står för frakten i hela världen"}
      />
      <PerksItem
        Image={CardIcon}
        title={"Säkra betalningar"}
        subtitle={"Betala säkert med kort eller med Klarna"}
      />
      <PerksItem
        Image={SproutIcon}
        title={"Klimatsmart"}
        subtitle={"Varje köp bidrar till rensning av koldioxid ur atmosfären"}
      />
    </Grid>
  );
};

export default PerksRow;
