import React from "react";
import Head from "next/head";

interface SEOProps {
  url?: string;
  title?: string;
  description?: string;
  image?: string;
  createdAt?: string;
  updatedAt?: string;
}

const socialTags = ({
  url,
  title,
  description,
  image,
  createdAt,
  updatedAt,
}) => [
  { name: "twitter:card", content: "summary_large_image" },
  { name: "twitter:site", content: "@stockiful" },
  { name: "twitter:title", content: title },
  { name: "twitter:description", content: description },
  { name: "twitter:creator", content: "@stockiful" },
  { name: "twitter:image:src", content: image },
  { name: "twitter:card", content: "summary_large_image" },
  { name: "og:title", content: title },
  { name: "og:type", content: "website" },
  { name: "og:url", content: url },
  { name: "og:image", content: image },
  { name: "og:description", content: description },
  { name: "og:site_name", content: "Stockiful" },
  { name: "og:published_time", content: createdAt },
  { name: "og:modified_time", content: updatedAt },
];

const SEO = ({
  url = "https://stockiful.com",
  title = "Stockiful aktieposters",
  description = "Din bästa affär på väggen! Välj mellan tusentals aktier och designa din egen aktieposter.",
  image = "https://stockiful.com/images/frontpage/tripple-couch.png",
  createdAt = new Date().toISOString(),
  updatedAt = new Date().toISOString(),
}: SEOProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="name" content={title} />
      <meta property="description" content={description} />
      <meta property="image" content={image} />
      {socialTags({ url, title, description, image, createdAt, updatedAt }).map(
        ({ name, content }) => {
          return <meta key={name} name={name} content={content} />;
        }
      )}
    </Head>
  );
};

export default SEO;
