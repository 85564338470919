import React from "react";
import Link from "next/link";

import { Container, Typography, Button, Box, Grid } from "@mui/material";

import StoryGrid from "../components/StoryGrid";
import SEO from "../components/SEO";
import PerksRow from "../components/perksrow";
import { GetStaticProps, NextPage } from "next/types";
import { fetchContent } from "../utils/strapi";
import { FrontpageRow, Header, ImageRow, type Frontpage } from "../types";
import ProductRow from "../components/ProductRow";

export const getStaticProps: GetStaticProps<Frontpage> = async () => {
  const res = await fetchContent<Frontpage>("/frontpage", {
    populate: {
      body: {
        populate: {
          items: {
            populate: "*",
          },
          image: {
            populate: "*",
          },
        },
      },
      header: "*",
    },
  });

  return {
    props: {
      header: res.data.attributes.header,
      body: res.data.attributes.body,
    },
  };
};

const styles = {
  backdrop: {
    backgroundImage: `linear-gradient(to left, rgba(0,0,0,.1), rgba(0,0,0,.5) ), url(/images/slideshow/bg03.jpg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "calc(60vh - 56px)",
    backgroundPosition: "top",
  },
  homeHeader: {
    width: "100%",
    height: "100%",
  },
  ctaBox: {
    padding: "1rem  ",
    borderRadius: 8,
    backdropFilter: "blur(10px)",
  },
};

type HeadingProps = {
  header: Header;
};

const HomepageHeading = ({ header }: HeadingProps) => {
  return (
    <Box style={styles.backdrop}>
      <Grid
        style={styles.homeHeader}
        direction="row"
        container
        justifyContent="space-evenly"
        alignContent="center"
      >
        <Grid item style={styles.ctaBox}>
          <Typography maxWidth={380} variant="h1">
            {header.title}
          </Typography>
          <Typography mt={1} mb={1} variant="subtitle2">
            {header.subtitle}
          </Typography>
          <Link href={"product"}>
            <Button variant="contained">{header.cta}</Button>
          </Link>
        </Grid>
        <Grid item />
        <Grid item />
      </Grid>
    </Box>
  );
};

const Home: NextPage<Frontpage> = (props) => {
  function renderRow(row: FrontpageRow, index: number) {
    if (row.__component.startsWith("product-row")) {
      return <ProductRow key={index} collection={row} />;
    } else if (row.__component.startsWith("image-block")) {
      return <StoryGrid key={index} item={row as ImageRow} />;
    }
  }
  return (
    <>
      <SEO />
      <HomepageHeading header={props.header} />
      <Container sx={{ padding: "0px", paddingTop: "16px" }}>
        <PerksRow />
        {props.body.map((row, index) => renderRow(row, index))}
      </Container>
    </>
  );
};

export default Home;
